import { getRequestEvent, isServer } from 'solid-js/web';
import { ShareIosExport } from '../generated/share-ios-export';
import { ShareAndroid } from '../generated/share-android';

const isApple = () => {
	const ua = (isServer ? getRequestEvent()?.request.headers.get('user-agent') : window.navigator.userAgent) ?? '';
	return (
		['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(ua) ||
		// iPad on iOS 13 detection
		ua.includes('Mac')
	);
};
export function Share() {
	return (isApple() ? ShareIosExport() : ShareAndroid()).replace(/id="[^"]+"/, 'id="icon-share"');
}
